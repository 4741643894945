<template>
  <v-tooltip v-if="locked" bottom>
    <template #activator="{ on }">
      <v-btn
        v-bind="$attrs"
        icon
        data-testid="lockBtn"
        @click="onUnlockClick"
        v-on="on"
      >
        <v-icon>$lock</v-icon>
      </v-btn>
    </template>
    <span>Unlock</span>
  </v-tooltip>
  <v-tooltip v-else bottom>
    <template #activator="{ on }">
      <v-btn v-bind="$attrs" icon @click="onLockClick" v-on="on">
        <v-icon>$unlock</v-icon>
      </v-btn>
    </template>
    <span>Lock</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "LockButton",
  inheritAttrs: false,
  computed: {
    locked() {
      return this.$store.getters["auth/isTeamModeLocked"];
    },
  },
  methods: {
    onLockClick() {
      this.$store.dispatch("auth/lockTeamMode");
    },
    onUnlockClick() {
      this.$store.dispatch("auth/employeeAccessNeeded");
    },
  },
};
</script>
